/* eslint-disable @typescript-eslint/no-explicit-any */
import { createNullCache } from '@algolia/cache-common'
import {
  MultipleQueriesQuery,
  MultipleQueriesResponse,
  SearchForFacetValuesResponse,
  SearchOptions,
} from '@algolia/client-search'
import algoliaSearch from 'algoliasearch'

interface PromiseExecutor<T> {
  resolve?: (value: T | PromiseLike<T>) => void
  reject?: (reason?: any) => void
}
type AlgoliaQuery<T = any> = MultipleQueriesQuery & {
  promise: PromiseExecutor<T>
}

export interface AlgoliaClientConfig {
  host: string
  appId: string
  apiKey: string
}

export type AlgoliaClientInstance = ReturnType<typeof algoliaClient>

function isSearchForFacetValuesResponse<TObject>(
  results: MultipleQueriesResponse<TObject>['results'][0]
): results is SearchForFacetValuesResponse {
  return 'facetHits' in results
}

export const algoliaClient = ({ appId, apiKey, host }: AlgoliaClientConfig) => {
  let queries: AlgoliaQuery[] = []
  let timeout: ReturnType<typeof setTimeout>

  const getAlgoliaClient = () =>
    algoliaSearch(appId, apiKey, {
      responsesCache: createNullCache(),
      hosts: [{ url: host }],
    })

  /**
   * Method to add a new Algolia query to the query buffer.
   * Additionally, it will trigger the execution of the buffer automatically
   * when all the queries has been added in that period of execution time.
   * @param {I extends string} indexName Algolia index name
   * @param {string} query Algolia mandatory query string
   * @param {T extends SearchOptions} params Algolia query parameters
   */
  const algoliaRequest = <
    TObject,
    I extends string = string,
    T extends SearchOptions = SearchOptions,
  >(
    indexName: I,
    query: string,
    params: T
  ) => {
    const executor: PromiseExecutor<TObject> = {
      resolve: undefined,
      reject: undefined,
    }

    const promise = new Promise<TObject>((resolve, reject) => {
      executor.resolve = resolve
      executor.reject = reject
    })

    queries.push({
      indexName,
      query,
      promise: executor,
      params: { attributesToHighlight: [], ...params },
    })

    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(() => {
      triggerMultipleQuery()
    }, 0)

    return promise
  }

  /**
   * Method to execute manually the list of stored Algolia queries.
   * When called, it will launch one Algolia Multiple Query and clear the query buffer.
   * If there is a pending timeout, this will be deleted.
   */
  const executeAlgoliaQueries = () => {
    if (timeout) {
      clearTimeout(timeout)
      triggerMultipleQuery()
    }
  }

  /**
   * Execute a multiple query on Algolia
   */
  const triggerMultipleQuery = () => {
    const tempQueries = [...queries]

    // Reset queries
    queries = []

    // Algolia multiple queries only needs index, query and params
    const parsedQueries = tempQueries.map(({ indexName, query, params }) => ({
      indexName,
      query,
      params,
    }))

    if (tempQueries.length) {
      getAlgoliaClient()
        // multipleQueries makes one singular HTTP request for all query data, if 1 query fails, they all fail
        .multipleQueries(parsedQueries)
        .then(({ results }) => {
          // when all queries are successful, we resolve all promises in the same order they were added to the query array
          tempQueries.forEach(({ promise }, index) => {
            const value = results[index]
            if (value && isSearchForFacetValuesResponse(value)) {
              // istanbul ignore next
              promise.resolve?.(value.facetHits)
            } else {
              const { index: _, ...rest } = value
              // istanbul ignore next
              promise.resolve?.(rest)
            }
          })
        })
        .catch((error) => {
          // if any one of the multiple queries fail, processing is halted and all queries are rejected
          tempQueries.forEach(({ promise }, index) => {
            // istanbul ignore next
            promise.reject?.({
              ...(typeof error === 'object' ? error : {}),
              queryParameters: parsedQueries[index],
            })
          })
        })
    }
  }

  return {
    getAlgoliaClient,
    algoliaRequest,
    executeAlgoliaQueries,
    triggerMultipleQuery,
  }
}
