import { AlgoliaClientInstance } from '../algoliaClient'
import { AlgoliaAuction, AlgoliaAuctionIndices } from './types'

export function getAlgoliaAuctionsByObjectIDs({
  getAlgoliaClient,
}: AlgoliaClientInstance) {
  return async ({
    index = 'auctions_sort_hammer_time_asc',
    objectIDs,
  }: GetAlgoliaAuctionsByObjectIDsParams): Promise<
    (AlgoliaAuction | null)[]
  > => {
    return (
      await getAlgoliaClient()
        .initIndex(index)
        .getObjects<AlgoliaAuction>(
          [objectIDs]
            .flat()
            .filter(Boolean)
            .map((objectID) => objectID.toString())
        )
    ).results
  }
}

interface GetAlgoliaAuctionsByObjectIDsParams {
  index?: AlgoliaAuctionIndices
  objectIDs: string[] | number[] | number | string
}
