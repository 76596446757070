import {
  keepPreviousData as keepPreviousDataFn,
  useQuery,
} from '@tanstack/react-query'

import { AlgoliaClientInstance } from '../algoliaClient'
import { getAlgoliaAuctionsByObjectIDs } from './getAlgoliaAuctionsByObjectIDs'
import { AlgoliaAuctionIndices } from './types'

export function useGetAlgoliaAuctionsByObjectIDs({
  index,
  objectIDs,
  client,
  keepPreviousData,
  enabled,
  staleTime,
  refetchOnMount,
}: {
  index?: AlgoliaAuctionIndices
  objectIDs: number[]
  client: AlgoliaClientInstance
  keepPreviousData?: boolean
  enabled?: boolean
  staleTime?: number
  refetchOnMount?: boolean
  auctionIDs?: never
}) {
  // We use a standard Query here because an Algolia ObjectIDs response does not include any pagination data
  const { data, error, isSuccess, isFetching, isError } = useQuery({
    queryKey: ['auctions', { index, auctionIds: objectIDs }],
    queryFn: () => getAlgoliaAuctionsByObjectIDs(client)({ index, objectIDs }),
    placeholderData: keepPreviousData ? keepPreviousDataFn : undefined,
    enabled,
    staleTime,
    refetchOnMount,
  })

  const finalData = data ?? []

  return { data: finalData, error, isSuccess, isFetching, isError }
}
